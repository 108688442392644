<template>
	<PopupTwoCol name="oleoduc"
	text="<h3>Oléoduc (Donges – Vern)</h3><br/><p>Le pipeline est surveillé de trois manières : depuis une salle de contrôle de la raffinerie de Donges (24 h /24 h), par surveillance aérienne et par surveillance terrestre (un marcheur suit le trajet de la canalisation afin d’effectuer des vérifications). Le passage du pipeline est balisé par des dispositifs blancs et rouges avec des numéros.</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/Schema_pipeline_izvkia_c_scale_w_350.jpg 350w,
		img/popup/Schema_pipeline_izvkia_c_scale_w_701.jpg 701w,
		img/popup/Schema_pipeline_izvkia_c_scale_w_970.jpg 970w"
		data-src="img/popup/Schema_pipeline_izvkia_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
	<picture slot="thumbnail__2">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/depot_de_Vern_2_cjojwm_c_scale_w_350.jpg 350w,
		img/popup/depot_de_Vern_2_cjojwm_c_scale_w_633.jpg 633w,
		img/popup/depot_de_Vern_2_cjojwm_c_scale_w_855.jpg 855w"
		data-src="img/popup/depot_de_Vern_2_cjojwm_c_scale_w_855.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
